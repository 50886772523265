import whitelabelService from "services/WhitelabelService";

import { notification } from "antd";

let notificationParam = {
  message: "",
};

export const getWhitelabelUsers = () => {
  return new Promise((resolve, reject) => {
    whitelabelService
      .getWhitelabelUsers()
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        notificationParam.message = error.message;
        notification.error(notificationParam);
        reject();
      });
  });
};

export const updateWhitelabelUsers = (id,data) => {
  return new Promise((resolve, reject) => {
    whitelabelService
      .updateWhiteLabelUser(id,data)
      .then((response) => {
        notificationParam.message = "User has been updated successfully";
        notification.success(notificationParam);
        resolve(response?.data?.data);
      })
      .catch((error) => {
        notificationParam.message = "Failed to update the user. Please check the provided data";
        notification.error(notificationParam);
        reject();
      });
  });
}

export const changePasswordWhitelabelUser = (id, newPassword) => {
  return new Promise((resolve, reject) => {
    whitelabelService
      .changeWhiteLabelUserPassword(id, newPassword)
      .then((response) => {
        notificationParam.message = "Password has been changed successfully";
        notification.success(notificationParam);
        resolve(response);
      })
      .catch((error) => {
        notificationParam.message = error.response?.data?.message || "Failed to change the password. Please try again";
        notification.error(notificationParam);
        reject(error);
      });
  });
};


export const changeWhitelabelUserPlan = (id, newPlanIds) => {
  return new Promise((resolve, reject) => {
    whitelabelService
      .changeWhiteLabelUserPlan(id, newPlanIds)
      .then((response) => {
        notificationParam.message = "Plan has been changed successfully";
        notification.success(notificationParam);
        resolve(response);
      })
      .catch((error) => {
        notificationParam.message = error.response?.data?.message || "Failed to change the plan. Please try again";
        notification.error(notificationParam);
        reject(error);
      });
  });
};

export const deleteWhitelabelUsers = (id) => {
  return new Promise((resolve, reject) => {
    whitelabelService
      .deleteWhiteLabelUser(id)
      .then((response) => {
        notificationParam.message = "User has been deleted successfully";
        notification.success(notificationParam);
        resolve(response?.data?.data);
      })
      .catch((error) => {
        notificationParam.message = "Failed to delete the user. Please check the provided data";
        notification.error(notificationParam);
        reject();
      });
  });
}

export const addWhitelabelUsers = (data) => {
  return new Promise((resolve, reject) => {
    whitelabelService
      .addWhiteLabelUser(data)
      .then((response) => {
        notificationParam.message = "User has been added successfully";
        notification.success(notificationParam);
        resolve(response);
      })
      .catch((error) => {
        notificationParam.message = error.message;
        notification.error(notificationParam);
        reject();
      });
  });

}

export const addWhitelabelSettings = (data) => {
  return new Promise((resolve, reject) => {
    whitelabelService
      .addWhiteLabelSettinngs(data)
      .then((response) => {
        notificationParam.message = "Settings been added successfully";
        notification.success(notificationParam);
        resolve(response);
      })
      .catch((error) => {
        notificationParam.message = error.message;
        notification.error(notificationParam);
        reject();
      });
  });
};

export const getWhiteLabelSettings = () => {
  return new Promise((resolve, reject) => {
    whitelabelService
      .getWhiteLabelSettings()
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        notificationParam.message = error.message;
        notification.error(notificationParam);
        reject();
      });
  });
};


export const getDataFromDomain = (domain) => {
  return new Promise((resolve, reject) => {
    whitelabelService
      .getDataFromDomain(domain)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        notificationParam.message = error.message;
        notification.error(notificationParam);
        reject();
      });
  });
}

