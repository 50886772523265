import React, { createContext, useEffect, useState } from 'react';
import ProfileService from 'services/ProfileService';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {

    const isAuthenticated = () => {
      const profileData = localStorage.getItem('profile');
      if (!profileData) {
        return false;
      }
      return true;
    }

    if (isAuthenticated()) {
      ProfileService.getProfile()
        .then((data) => {
          setUserProfile(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

  }
    , []);

  return (
    <UserContext.Provider value={{ userProfile, setUserProfile }}>
      {children}
    </UserContext.Provider>
  );
};
