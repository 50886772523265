import fetch from 'auth/FetchInterceptor'

const ProfileService = {}

ProfileService.getProfile = function () {
    return fetch({
        url: '/profile',
        method: 'get'
    })
}

ProfileService.updateProfile = function (data) {
    return fetch({
        url: '/profile',
        method: 'put',
        data: data
    })
}

ProfileService.getAIKeys = function () {
    return fetch({
        url: '/ai-keys',
        method: 'get'
    })
}

ProfileService.saveAIKey = function (data) {
    const payload = {
        provider: data.provider,
        aiKey: data.apiKey,
        name: data.name
    };
    
    return fetch({
        url: '/ai-key',
        method: 'post',
        data: payload
    })
}

ProfileService.deleteAIKey = function (id) {
    return fetch({
        url: `/ai-key/${id}`,
        method: 'delete'
    })
}

ProfileService.setDefaultAIProvider = function (keyId) {
    return fetch({
        url: '/ai-key/default',
        method: 'put',
        data: { keyId }
    })
}
ProfileService.validateKey = function (data) {
    return fetch({
        url: '/ai-key/validate',
        method: 'post',
        data: data
    })
}

export default ProfileService;